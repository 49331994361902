/** @jsxImportSource @emotion/core */

import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";

import { ReactComponent as HubSpotLogo } from "../assets/hubspot-logo.svg";
import { useAppSettings } from "./AppSettings";
import { redirectToAuth } from "../api";
import Loading from "./Loading";
import { StyledButton } from "./ui-common";

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-height: 80%;
  overflow: auto;
  padding: 65px;
  background: white;
  text-align: center;
  color: #000000;
  pointer-events: auto;
  width: 540px;
  max-width: 90%;
`;
const Title = styled.h4`
  font-weight: bold;
  font-size: 21.328px;
  line-height: 26px;
  color: inherit;
  margin: 0;
`;
const StyledSignInButton = styled(StyledButton)`
  width: 350px;
  margin-top: 16px;
`;
function SignInButton({ onAnswer = () => {} }: { onAnswer?: (value: string) => void }) {
  return (
    <StyledSignInButton onClick={() => onAnswer("")}>
      <HubSpotLogo />
      Sign in with HubSpot
    </StyledSignInButton>
  );
}
function RedirectToAuth() {
  const appSettings = useAppSettings();
  redirectToAuth(appSettings.portalId);
  return <></>;
}
function Steps() {
  const [index, setIndex] = useState(0);
  const onAnswer = useCallback(() => {
    setIndex(index + 1);
  }, [index]);
  if (index >= 1) {
    return (
      <>
        <Loading />
        <RedirectToAuth />
      </>
    );
  }
  return (
    <>
      <SignInButton onAnswer={onAnswer} />
    </>
  );
}
export default function SignInPopup() {
  return (
    <Popup>
      <Title>To continue please sign in</Title>
      <Steps />
    </Popup>
  );
}

import React from "react";
import { useAppSettings } from "./AppSettings";

export const TEST_PORTALS = [
  "346178",
  "358710",
  "7910873",
  "8814888",
  "464751",
  "8972852",
  "8933652",
  "9169067",
  "9175017",
  "9193757",
  "1624307",
  "14485745",
  "8725880",
  "318095",
];

export function isTestPortal(portalId: string | number) {
  return TEST_PORTALS.includes(portalId.toString());
}

export function TestOnly({ children }: { children: React.ReactNode }) {
  const appSettings = useAppSettings();
  if (!isTestPortal(appSettings.portalId!)) {
    return <></>;
  }
  return <>{children}</>;
}

/* eslint-disable no-restricted-globals */

import { SavedPattern } from "./components/UiContext";

const API_ROOT = "https://pnwiko0ikk.execute-api.us-east-1.amazonaws.com/prod";

function urlSafeBase64Encode(input: string) {
  return window
    .btoa(unescape(encodeURIComponent(input)))
    .replace(/\+/g, "-") // Convert '+' to '-'
    .replace(/\//g, "_") // Convert '/' to '_'
    .replace(/=+$/, ""); // Remove ending '='
}
function _fetch(uri: string, options?: RequestInit | undefined) {
  return fetch(uri, options)
    .then((resp) => {
      if (!resp.ok) {
        return Promise.reject(resp);
      }
      return resp.json();
    })
    .catch((e) => {
      return e.json
        ? e.json().then((resp: any) => {
            if (resp.success === false && resp.retry === true) {
              console.log("Retrying:", uri, resp);
              return _fetch(uri, options);
            }
            return Promise.reject(resp);
          })
        : Promise.reject(e);
    })
    .then((data) => {
      if (data.error) {
        return Promise.reject(data);
      }
      if (process.env.NODE_ENV !== "production") {
        console.log(data);
      }
      return data;
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });
}
export function savePattern({
  name,
  description,
  image,
  source,
  global,
  token,
  pageId,
  dndAreaId,
  rowId,
}: {
  name: string;
  description: string;
  image: string;
  source: string;
  global: boolean;
  token: string;
  pageId: string;
  dndAreaId: string;
  rowId: number;
}) {
  return _fetch(`${API_ROOT}/save`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionParams: token,
      data: urlSafeBase64Encode(
        JSON.stringify({
          name,
          description,
          image,
          source,
          global,
          pageId,
          dndAreaId,
          rowId,
        })
      ),
    }),
  });
}
export function updatePattern({
  name,
  description,
  image,
  source,
  global,
  token,
  patternId,
}: {
  name: string;
  description: string;
  image: string;
  source: string;
  global: boolean;
  token: string;
  patternId: string;
}) {
  return _fetch(`${API_ROOT}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionParams: token,
      data: urlSafeBase64Encode(
        JSON.stringify({
          name,
          description,
          image,
          source,
          global,
          id: patternId,
        })
      ),
    }),
  });
}
export function deletePattern({ token, patternId }: { token: string; patternId: string }) {
  return _fetch(`${API_ROOT}/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionParams: token,
      data: urlSafeBase64Encode(
        JSON.stringify({
          id: patternId,
        })
      ),
    }),
  });
}
export function forceRefreshSectionData({ pageId, token }: { pageId: string; token: string }) {
  return new Promise((resolve, reject) => {
    const handler = (e: MessageEvent) => {
      if (e.data.type === "json") {
        const data = JSON.parse(e.data.body);
        if (data.action === "setContentSchema") {
          window.removeEventListener("message", handler);
          forceRefreshSectionDataApi({
            token: token,
            pageId: pageId,
            layoutSections: data.schema.layout_sections,
          })
            .then(resolve)
            .catch(reject);
        }
      }
    };
    window.addEventListener("message", handler);
    window.parent.postMessage(
      {
        type: "raw",
        _start: new Date().getTime(),
        _callbackId: "preview.hs-sites.com-x",
        body: { action: "inpageAppInitializationStarted" },
      },
      "https://app.hubspot.com"
    );
  });
}
function forceRefreshSectionDataApi({
  pageId,
  layoutSections,
  token,
}: {
  pageId: string;
  layoutSections: unknown;
  token: string;
}) {
  return _fetch(`${API_ROOT}/force-refresh-sections`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionParams: token,
      data: urlSafeBase64Encode(
        JSON.stringify({
          pageId,
          layoutSections,
        })
      ),
    }),
  });
}
export function insertPattern({
  id,
  token,
  pageId,
  dndAreaId,
  rowId,
}: {
  id: string;
  token: string;
  pageId: string;
  dndAreaId: string;
  rowId: number;
}): Promise<{ haveUnknownModules: boolean }> {
  return _fetch(`${API_ROOT}/insert`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionParams: token,
      data: urlSafeBase64Encode(
        JSON.stringify({
          id,
          pageId,
          dndAreaId,
          rowId,
        })
      ),
    }),
  });
}
export function getSavedPatterns({ token, pageId }: { token: string; pageId: string }): Promise<SavedPattern[]> {
  return _fetch(`${API_ROOT}/get-saved-patterns`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionParams: token,
      pageId,
    }),
  });
}
export function forceSave() {
  window.addEventListener("message", (e) => {
    if (e.data?.body?.action === "checkForPendingInpageUpdates") {
      sessionStorage.removeItem("brixPendingForceSave");
      setTimeout(function () {
        window.parent.postMessage(
          {
            type: "raw",
            _start: new Date().getTime(),
            _callbackId: "preview.hs-sites.com-x",
            body: {
              action: "reduxAction",
              source: "iframe",
              type: "CLEAR_UPDATED_THEME_SETTINGS",
            },
          },
          "https://app.hubspot.com"
        );
        window.parent.postMessage(
          {
            type: "raw",
            _start: new Date().getTime(),
            _callbackId: "preview.hs-sites.com-xx",
            body: {
              action: "RELOAD_INPAGE_IFRAME",
            },
          },
          "https://app.hubspot.com"
        );
      }, 1000);
    }
  });
  setDirty();
  window.addEventListener("load", setDirty);
}
export function setDirty() {
  window.parent.postMessage(
    {
      type: "raw",
      _start: new Date().getTime(),
      _callbackId: "preview.hs-sites.com-xx",
      body: {
        action: "reduxAction",
        source: "iframe",
        type: "SET_DIRTY_FLAG",
        payload: {
          isDirty: true,
          isHardSavePending: true,
        },
      },
    },
    "https://app.hubspot.com"
  );
}
export function redirectToAuth(portalId?: string) {
  location.href = `${API_ROOT}/run/${urlSafeBase64Encode(
    JSON.stringify({
      authRedirect: location.href,
      skipHubDb: true,
      portalId,
    })
  )}`;
}

/** @jsxImportSource @emotion/core */

import React from "react";
import styled from "@emotion/styled/macro";
import { css, keyframes } from "@emotion/core";

const keyFrames = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoading = styled.div<{ size: number; thickness: number; margin: number; color: string; center: boolean }>`
  display: block;
  margin: ${(props) => props.margin}px auto;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;

  ${(props) =>
    props.center
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : ""}

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(props) => props.size - props.thickness * 2}px;
    height: ${(props) => props.size - props.thickness * 2}px;
    margin: ${(props) => props.thickness}px;
    border: ${(props) => props.thickness}px solid ${(props) => props.color};
    border-radius: 50%;
    animation: ${keyFrames} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color} transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
export default function Loading({ size = 80, thickness = 8, margin = 20, color = "#ddd", center = false, className = "" }) {
  return (
    <StyledLoading size={size} thickness={thickness} margin={margin} color={color} center={center} className={className}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoading>
  );
}

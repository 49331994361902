/** @jsxImportSource @emotion/core */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";

import { useUiContext } from "./UiContext";
import { DisableHsBox, Menu, MenuItem } from "./ui-common";
import { useAuthContext } from "./AuthContext";
import { TestOnly } from "./TestPortal";

const StyledSectionBox = styled.div`
  box-sizing: border-box;
  position: absolute;
`;

const ActionButton = styled.button`
  pointer-events: auto;
  background: #e85338;
  border: 1px solid black;
  border-radius: 0;
  position: absolute;
  top: 35px;
  left: 0;
  box-sizing: border-box;
  height: 26px;
  width: 111px;
  font-family: "Avenir Next W02", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: white;
  line-height: 1;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #ea745f;
  }
  &::after {
    content: "downCarat";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: spacesword !important;
    font-feature-settings: "liga";
    font-size: 10px;
    line-height: 20px;
    display: block;
    position: absolute;
    top: 4px;
    right: 4.25px;
  }
`;
const SectionMenu = styled(Menu)`
  top: 80px;
  left: 101px;
  transform: translateX(-50%);

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-width: 0 14px 14px 14px;
    border-color: transparent transparent #ffffff transparent;
    border-style: solid;
  }
`;
const SectionHighlightBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(127, 209, 222, 0.3);
  outline: 3px solid #0091ae;
  outline-offset: -3px;
  z-index: -1;
  pointer-events: auto;
`;

export default function SectionBox({ sectionElem }: { sectionElem: HTMLElement }) {
  const [popOutVisible, setPopOutVisible] = useState(false);
  const authContext = useAuthContext();
  useEffect(() => setPopOutVisible(false), [sectionElem]);
  const box = useMemo(() => {
    const clientRect = sectionElem.getClientRects()[0];
    return {
      left: clientRect.left + window.pageXOffset,
      top: clientRect.top + window.pageYOffset,
      width: clientRect.width,
      height: clientRect.height,
    };
  }, [sectionElem]);
  const [uiContext, updateUi] = useUiContext();
  const activateSidebar = useCallback(
    (e: React.MouseEvent, mode: "insert" | "save") => {
      e.preventDefault();
      e.stopPropagation();
      if (!authContext.authenticated) {
        updateUi({
          activePopup: "signIn",
        });
        return;
      }
      updateUi({
        sidebarActive: {
          mode,
          areaId: sectionElem.parentElement?.getAttribute("data-hs-cell-id") || "INVALID",
          sectionId: parseInt((/(\d+)$/.exec(sectionElem.getAttribute("data-hs-row-id") || "") || ["99999"])[1], 10),
        },
      });
    },
    [authContext.authenticated, sectionElem, updateUi]
  );
  return (
    <StyledSectionBox style={box} onClick={() => updateUi({ sidebarActive: false })}>
      {!uiContext.sidebarActive && (
        <>
          <ActionButton onClick={() => setPopOutVisible(!popOutVisible)}>BRiX</ActionButton>
          <SectionMenu
            show={popOutVisible}
            onClickCapture={() => setPopOutVisible(false)}
            onClick={(e) => e.stopPropagation()}
          >
            <MenuItem onClick={() => updateUi({ activePopup: "welcome" })}>Get started</MenuItem>
            <MenuItem onClick={(e) => activateSidebar(e, "save")}>Save pattern</MenuItem>
            <MenuItem onClick={(e) => activateSidebar(e, "insert")}>Insert pattern</MenuItem>
            <MenuItem onClick={() => updateUi({ gridActive: !uiContext.gridActive })}>Toggle grid</MenuItem>
            <TestOnly>
              <MenuItem
                onClick={() =>
                  window.open(
                    authContext.authenticated
                      ? `https://wam.gobrix.io/auth-handover?token=${authContext.token}`
                      : "https://wam.gobrix.io",
                    "_blank"
                  )
                }
              >
                Open WAM
              </MenuItem>
            </TestOnly>
          </SectionMenu>
        </>
      )}
      {popOutVisible && <DisableHsBox />}
      {(popOutVisible || uiContext.sidebarActive) && <SectionHighlightBox onClick={() => setPopOutVisible(false)} />}
    </StyledSectionBox>
  );
}

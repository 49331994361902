/** @jsxImportSource @emotion/core */

import React, { useCallback, useEffect, useState } from "react";
import ExternalWidget from "./ExternalWidget";

import SectionBox from "./SectionBox";
import { useUiContext } from "./UiContext";

export default function SectionController() {
  const [dndSection] = useState(() => {
    const section = document.querySelector('.widget-type-cell[data-hs-cell-id="dnd_area"]');
    if (section) {
      return section;
    }
    const innerSection = document.querySelector(".dnd-section");
    if (innerSection) {
      return innerSection.parentElement;
    }
    return null;
  });
  const [activeSection, setActiveSection] = useState(null as HTMLElement | null);
  const [uiContext] = useUiContext();
  const onMouseMove = useCallback(
    (e: Event) => {
      let target = e.target as HTMLElement;
      while (target && target !== dndSection) {
        if (target.parentNode === dndSection && target.classList.contains("dnd-section")) {
          if (activeSection === target) {
            return;
          }
          setActiveSection(target);
          return;
        }
        if (target.classList && target.classList.contains("_HS-react-root")) {
          return;
        }
        if (target.shadowRoot && target.shadowRoot.querySelector(".brix-shadow-app-root")) {
          return;
        }
        target = target.parentNode as HTMLElement;
      }
      if (!activeSection) {
        return;
      }
      setActiveSection(null);
    },
    [dndSection, activeSection, setActiveSection]
  );
  const onMouseLeave = useCallback(() => {
    if (activeSection) {
      setActiveSection(null);
    }
  }, [activeSection, setActiveSection]);
  useEffect(() => {
    if (!dndSection) {
      return;
    }
    if (uiContext.sidebarActive) {
      return;
    }
    document.addEventListener("mousemove", onMouseMove, true);
    document.documentElement.addEventListener("mouseleave", onMouseLeave);
    return () => {
      document.removeEventListener("mousemove", onMouseMove, true);
      document.documentElement.removeEventListener("mouseleave", onMouseLeave);
    };
  }, [dndSection, onMouseMove, onMouseLeave, uiContext.sidebarActive]);
  return (
    <div>
      {activeSection ? (
        <SectionBox sectionElem={activeSection} />
      ) : (
        <ExternalWidget>
          <style type="text/css">{`
          .hs-ls-module-hover-overlay {
            pointer-events: none !important;
          }
        `}</style>
        </ExternalWidget>
      )}
    </div>
  );
}

/** @jsxImportSource @emotion/core */

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled/macro";

import { useAppSettings } from "./AppSettings";
import { redirectToAuth } from "../api";
import Loading from "./Loading";
import { StyledButton } from "./ui-common";
import { useAuthContext } from "./AuthContext";
import { useUiContext } from "./UiContext";
import css from "@emotion/css/macro";

const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  max-height: 80%;
  overflow: auto;
  padding: 65px;
  background: white;
  text-align: center;
  color: #000000;
  pointer-events: auto;
  width: 540px;
  max-width: 90%;
`;
const Title = styled.h4`
  font-weight: bold;
  font-size: 21.328px;
  line-height: 26px;
  color: inherit;
  margin: 0;
`;
const HubSpotFormContainer = styled.div`
  margin-top: 16px;
  text-align: left;

  select {
    background: #f4f7fc;
    border-radius: 3.5px;
    height: 54px;
    box-sizing: border-box;
    border: 2px solid #f4f7fc;
    width: 100%;
    padding: 0 15px;
  }
  .show-error:invalid {
    border-color: red;
  }
  input[type="submit"] {
    background: #2569e8;
    border-radius: 5px;
    color: white;
    height: 44px;
    box-sizing: border-box;
    min-width: 138px;
    cursor: pointer;
    display: block;
    margin: 18px auto 0;
    border: 0 none;
  }
  .hs-form-field {
    margin-top: 18px;
  }
`;

declare global {
  interface Window {
    hbspt: any;
    jQuery: any;
  }
}

function loadFormScript() {
  return new Promise((res) => {
    if (window.hbspt && window.hbspt.forms && window.hbspt.forms.create) {
      setTimeout(res, 10);
      return;
    }
    const scriptElem = document.createElement("script");
    scriptElem.onload = () => res();
    scriptElem.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(scriptElem);
  });
}

function HubSpotForm({
  formId = "7bf56498-3f77-4700-8b2b-38fe7fba0113",
  formPortalId = "346178",
  onSubmit = () => {},
}) {
  const appSettings = useAppSettings();
  const authContext = useAuthContext();
  const [loaded, setLoaded] = useState(false);
  const containerId = useMemo(() => "brixForm" + Math.random().toString().slice(2), []);
  const [formElem, setFormElem] = useState(document.createElement("div"));
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    loadFormScript().then(function () {
      const oldQuerySelector = document.querySelector.bind(document);
      const newFormElem = document.createElement("div");
      newFormElem.id = containerId;
      ref.current?.appendChild(newFormElem);
      document.querySelector = function (selector: string) {
        if (selector === "#" + containerId) {
          return newFormElem;
        }
        return oldQuerySelector.apply(this, arguments as any);
      };
      const hasJQuery = !!window.jQuery;
      if (!hasJQuery) {
        window.jQuery = (x: any) => x;
      }
      window.hbspt.forms.create({
        portalId: formPortalId,
        formId,
        target: "#" + containerId,
        onFormReady: function () {
          document.querySelector = oldQuerySelector;
          if (!hasJQuery) {
            delete window.jQuery;
          }
          setTimeout(function () {
            setFormElem(newFormElem);
            setLoaded(true);
            const form = newFormElem.querySelector("form");
            if (!form) {
              return;
            }
            newFormElem.querySelector('input[type="submit"]')?.addEventListener("click", function (e) {
              e.preventDefault();
              form.removeAttribute("novalidate");
              const fields = form.querySelectorAll("[name]");
              let hasError = false;
              const submissionData = {} as { [key: string]: string };
              for (let i = 0; i < fields.length; i++) {
                const field = fields.item(i) as HTMLInputElement;
                if (field.name === "hs_context") {
                  continue;
                }
                if (field.checkValidity) {
                  const error = !field.checkValidity();
                  field.classList.toggle("show-error", true);
                  if (error) {
                    hasError = true;
                  }
                  if (field.name && field.value) {
                    submissionData[field.name] = field.value;
                  }
                }
              }
              if (hasError) {
                return;
              }
              submissionData.email = authContext.authenticated ? authContext.email : "";
              submissionData.brix_portal_id = appSettings.portalId!;
              fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${formPortalId}/${formId}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  fields: Object.entries(submissionData).map(([name, value]) => ({ name, value })),
                }),
              }).catch((e) => console.error("Failed to submit portal sign up form:", e));
              const portalSignUpFinishedKey = `brixPortalSignUpFinished${appSettings.portalId}`;
              localStorage.setItem(portalSignUpFinishedKey, "1");
              onSubmit();
            });
          }, 100);
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (formElem && formElem.id && loaded && ref.current) {
      ref.current.appendChild(formElem);
    }
  }, [ref, formElem, loaded]);
  return (
    <HubSpotFormContainer>
      {!loaded && <Loading />}
      <div ref={ref} />
    </HubSpotFormContainer>
  );
}

export default function PortalSignUpPopup() {
  const [, updateUi] = useUiContext();
  const [submitted, setSubmitted] = useState(false);
  return (
    <Popup>
      {submitted ? (
        <>
          <Title>Thank you! You can now start</Title>
          <StyledButton
            css={css`
              margin-top: 18px;
            `}
            onClick={() => updateUi({ activePopup: "" })}
          >
            Close
          </StyledButton>
        </>
      ) : (
        <>
          <Title>Please let us know a few things about you</Title>
          <HubSpotForm onSubmit={() => setSubmitted(true)} />
        </>
      )}
    </Popup>
  );
}

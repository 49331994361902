/* eslint-disable no-restricted-globals */
import React, { useContext, useEffect, useState } from "react";
import { useAppSettings } from "./AppSettings";
import md5 from "../md5";

type AuthContextType =
  | {
      authenticated: false;
    }
  | {
      authenticated: true;
      avatar: string;
      token: string;
      email: string;
      signOut: () => void;
    };

const AuthContext = React.createContext<Readonly<AuthContextType>>({ authenticated: false });

export const useAuthContext = () => useContext(AuthContext);

function initAuthContext(portalId: string): AuthContextType {
  const params = new URLSearchParams(location.search);
  const tokenKey = `brixToken${portalId}`;
  if (params.has("sp") && portalId) {
    const info = JSON.parse(params.get("info") || "{}");
    localStorage.setItem(
      tokenKey,
      JSON.stringify({
        token: params.get("sp"),
        info,
      })
    );
    params.delete("sp");
    params.delete("info");
    params.delete("state");
    history.replaceState({}, "", location.pathname + "?" + params.toString());
  }
  if (localStorage[tokenKey]) {
    let authData: { token: string; info: { user: string } };
    try {
      authData = JSON.parse(localStorage[tokenKey]);
    } catch (e) {
      console.error("Failed to load saved auth data:", e);
      return { authenticated: false };
    }
    return Object.freeze({
      authenticated: true,
      token: authData.token,
      avatar: "https://www.gravatar.com/avatar/" + md5(authData.info.user.trim().toLowerCase()),
      email: authData.info.user,
      signOut: () => {
        localStorage.removeItem(tokenKey);
        location.reload();
      },
    });
  }
  return { authenticated: false };
}

export function AuthContextProvider({ children }: { children: React.ReactNode }) {
  const appSettings = useAppSettings();
  const [context, setContext] = useState<AuthContextType>(() => initAuthContext(appSettings.portalId!));
  useEffect(() => {
    setContext(initAuthContext(appSettings.portalId!));
  }, [appSettings]);
  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

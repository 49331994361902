/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import { CacheProvider } from "@emotion/core";
import createCache from "@emotion/cache";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import root from "react-shadow";
import { AppSettings, AppSettingsContext } from "./components/AppSettings";

dayjs.extend(localizedFormat);

(function init() {
  if (window.self === window.top) {
    return;
  }
  if ((window as any).__brixAppLoaded) {
    return;
  }
  if (!document.body) {
    setTimeout(init, 100);
    return;
  }
  (window as any).__brixAppLoaded = true;
  const appSettings: AppSettings = {};
  const appElem = document.getElementById("brix-app");
  const hsVars = (window as any).hsVars || {};
  if (appElem) {
    Object.assign(appSettings, {
      portalId: appElem.getAttribute("data-portal-id") || (hsVars.portal_id as string),
      pageId: appElem.getAttribute("data-page-id") || (hsVars.page_id as string),
    });
  }
  const params = new URLSearchParams(location.search);
  const tokenKey = `brixToken${appSettings.portalId}`;
  if (params.has("sp") && appSettings.portalId) {
    localStorage.setItem(tokenKey, params.get("sp") || "");
  }
  if (localStorage[tokenKey]) {
    appSettings.authToken = localStorage[tokenKey];
  }
  const styleRoot = document.createElement("div");

  const options = createCache({ container: styleRoot, speedy: false /* Needed for shadow DOM */ });

  const rootElement = document.createElement("div");
  document.body.appendChild(rootElement);

  const dummyDiv = document.createElement("div");

  function Style() {
    const ref = useRef<HTMLDivElement>(dummyDiv);
    useEffect(() => {
      if (styleRoot.parentNode) {
        styleRoot.parentNode.removeChild(styleRoot);
      }
      if (ref.current) {
        ref.current.appendChild(styleRoot);
      }
    }, [ref]);
    return <div ref={ref}></div>;
  }

  ReactDOM.render(
    <React.StrictMode>
      <AppSettingsContext.Provider value={appSettings}>
        <style type="text/css">{`
          .hs-private.layout-section-container {
            z-index: 2147480000 !important;
          }
          .brix-mask ._HS-react-root {
            display: none !important;
          }
        `}</style>
        <root.div>
          <CacheProvider value={options}>
            <Style />
            <App />
          </CacheProvider>
        </root.div>
      </AppSettingsContext.Provider>
    </React.StrictMode>,
    rootElement
  );
})();

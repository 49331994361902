import React, { useContext, useReducer } from "react";

export type SavedPattern = {
  id: string;
  name: string;
  description: string;
  image: string;
  source: string;
  creator: string;
  timestamp: number;
  themeName?: string;
  portal?: number | string;
  global: boolean;
  usedModules?: (string | number)[];
};

const DEFAULT_UI_CONTEXT = {
  sidebarActive: false as
    | false
    | { mode: "save"; areaId: string; sectionId: number; existingPattern?: SavedPattern | undefined }
    | { mode: "insert"; areaId: string; sectionId: number },
  gridActive: false,
  activePopup: "" as "" | "signIn" | "portalSignUp" | "welcome",
  savedPatterns: [] as SavedPattern[],
};
type UiContextType = typeof DEFAULT_UI_CONTEXT;

const UiContext = React.createContext<[Readonly<UiContextType>, (x: Partial<UiContextType>) => void]>([
  DEFAULT_UI_CONTEXT,
  () => {},
]);

export function useUiContext() {
  return useContext(UiContext);
}

export function UiContextProvider({ children }: { children: React.ReactNode }) {
  const [context, updateContext] = useReducer(
    (state: UiContextType, newState: Partial<UiContextType>) => Object.freeze({ ...state, ...newState }),
    null,
    () => Object.freeze(DEFAULT_UI_CONTEXT)
  );
  return <UiContext.Provider value={[context, updateContext]}>{children}</UiContext.Provider>;
}

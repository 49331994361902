/* eslint-disable no-restricted-globals */
/** @jsxImportSource @emotion/core */

import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import css from "@emotion/css/macro";

import { ReactComponent as BrixLogo } from "../../assets/brix-logo.svg";
import { ReactComponent as CloseButton } from "../../assets/close.svg";
import { ReactComponent as DropdownTriangle } from "../../assets/dropdown-triangle.svg";
// import { ReactComponent as QuestionButton } from "../../assets/question-button.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { ReactComponent as SpacesIcon } from "../../assets/spaces.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { useUiContext } from "../UiContext";
import { useAuthContext } from "../AuthContext";
import { Menu, MenuItem, VertCenterBlock } from "../ui-common";
import Mask from "../Mask";

const StyledSideBar = styled.div<{ active: boolean }>`
  width: 325px;
  max-width: 75%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  transition: transform 0.2s;
  transform: translateX(${(props) => (props.active ? "0%" : "100%")});
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
const Header = styled.div`
  height: 75px;
  position: relative;
  flex: 0 0 75px;
`;
const StyledBrixLogo = styled(BrixLogo)`
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
`;
const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
const headerRight = css`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;
const userBlockOverlay = css`
  position: relative;
  z-index: 1;
`;
const StyledUserBlock = styled(VertCenterBlock)`
  background: white;
  padding: 5px;
  border-radius: 21px;
  ${userBlockOverlay}
`;
const Avatar = styled.div<{ src: string }>`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 6px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("${(prop) => prop.src}");
  ${userBlockOverlay}
`;
/*
const StyledQuestionButton = styled(QuestionButton, {
  shouldForwardProp: (prop) => prop.indexOf("on") === 0,
})<{ show: boolean }>`
  cursor: pointer;
  display: block;
  margin-right: 5px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
  transition: opacity 0.2s;
  ${userBlockOverlay}
`;
*/
const UserMenu = styled(Menu)`
  top: 50%;
  right: 0;
  border-radius: 4px 0px 4px 4px;
`;
const UserBlock = function ({ showMenu = false, ...attrs }) {
  const authContext = useAuthContext();
  if (!authContext.authenticated) {
    return <></>;
  }
  return (
    <StyledUserBlock {...attrs}>
      <Avatar src={authContext.avatar} />
      <DropdownTriangle css={userBlockOverlay} />
      <UserMenu show={showMenu}>
        {/*
        <MenuItem>
          <SpacesIcon /> Spaces
        </MenuItem>
        <MenuItem>
          <SettingsIcon /> Settings
        </MenuItem>*/}
        <MenuItem
          onClick={() => window.open("https://www.figma.com/file/C7z0INtvrlWqdU89FuW2DK/Brix-boilerplate", "_blank")}
        >
          <SpacesIcon /> Skins
        </MenuItem>
        <MenuItem onClick={() => window.open("https://support.inboundlabs.co/en/brix-framework", "_blank")}>
          <SupportIcon /> Support
        </MenuItem>
        {authContext.authenticated && (
          <MenuItem onClick={() => authContext.signOut()}>
            <SettingsIcon /> Sign out
          </MenuItem>
        )}
      </UserMenu>
    </StyledUserBlock>
  );
};
const BodyWrapper = styled.div`
  overflow: auto;
  width: calc(100% + 300px);
  padding-left: 300px;
  box-sizing: border-box;
  align-self: flex-end;
  pointer-events: none;
`;
const Body = styled.div`
  padding: 0 25px 25px;
  flex: 1 1 auto;
  pointer-events: auto;
`;

const SideBar = function ({ children }: { children?: React.ReactNode }) {
  const [context, updateContext] = useUiContext();
  const authContext = useAuthContext();
  const [showUserMenu, setShowUserMenu] = useState(false);
  return (
    <StyledSideBar active={!!context.sidebarActive}>
      <Header>
        <StyledCloseButton onClick={() => updateContext({ sidebarActive: false })} />
        <StyledBrixLogo />
        {authContext.authenticated && (
          <VertCenterBlock
            css={[
              headerRight,
              css`
                z-index: 1;
              `,
            ]}
          >
            <UserBlock showMenu={showUserMenu} onClick={() => setShowUserMenu(!showUserMenu)} />
          </VertCenterBlock>
        )}
      </Header>
      <BodyWrapper>
        <Body>{children}</Body>
      </BodyWrapper>
      <Mask active={showUserMenu} onClick={() => setShowUserMenu(false)} />
    </StyledSideBar>
  );
};

export default SideBar;

/** @jsxImportSource @emotion/core */

import React, { InputHTMLAttributes } from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import ExternalWidget from "./ExternalWidget";

export const LinkButton = styled.button`
  border: 0 none;
  background: none;
  font-weight: bold;
  cursor: pointer;
`;
export const VertCenterBlock = styled.div`
  display: flex;
  align-items: center;
`;
export const Menu = styled.div<{ show: boolean }>`
  position: absolute;
  background: white;
  border-radius: 4px;
  padding: 10px 0;
  min-width: 110px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  opacity: ${(props) => (props.show ? 1 : 0)};
  pointer-events: ${(props) => (props.show ? "auto" : "none")};
  transition: opacity 0.2s;
`;
export const MenuItem = styled.a`
  display: flex;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #000000;
  padding: 12px 20px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #e5f5f8;
  }
  svg {
    display: block;
    margin-right: 6px;
  }
`;
export const GenericFormContainer = styled.div`
  margin-bottom: 24px;

  label {
    display: block;
  }
`;
const StyledInput = styled.input`
  height: 58px;
  box-sizing: border-box;
  border: 1px solid #c4c4c4;
  font-family: inherit;
  font-size: 16px;
  line-height: 150%;
  color: black;
  display: block;
  padding: 0 20px;
  width: 100%;
  margin-bottom: 24px;

  &::placeholder {
    opacity: 0.5;
  }
`;
export const TextBox = function ({ type = "text", ...attrs }) {
  return <StyledInput type={type} {...attrs} />;
};
export const Checkbox = function ({
  label,
  checked,
  ...attrs
}: { label: string } & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <label
      css={css`
        margin-bottom: 24px;
        display: block;
      `}
    >
      <input type="checkbox" {...attrs} /> {label}
    </label>
  );
};
export const Textarea = styled(StyledInput)`
  height: 104px;
  padding-top: 16px;
`.withComponent("textarea");
export const StyledButton = styled.button<{ cls?: "danger" | undefined }>`
  width: 100%;
  max-width: 100%;
  height: 55px;
  background: white;
  border: 1px solid #ff7a59;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ff7a59;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;

  ${(prop) =>
    prop.cls === "danger" &&
    css`
      color: white;
      background-color: #dc3545;
      border-color: #dc3545;
    `}

  svg {
    display: block;
    margin-right: 12px;
  }
`;
export const ViewportCanvas = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform;
  z-index: 2;
`;
export const DisableHsBox = () => (
  <ExternalWidget>
    <style type="text/css">{`
      ._HS-react-root {
        display: none !important;
      }
    `}</style>
  </ExternalWidget>
);

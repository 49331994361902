import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/core";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

export default function ExternalWidget({ children }: { children: React.ReactNode }) {
  const [element] = useState(() => document.createElement("div"));
  const options = useMemo(() => createCache({ container: element, speedy: false }), [element]);
  useEffect(() => {
    document.querySelector(".body-container")?.appendChild(element);
    return () => {
      element.parentNode?.removeChild(element);
    };
  }, [element]);
  return ReactDOM.createPortal(<CacheProvider value={options}>{children}</CacheProvider>, element);
}

/** @jsxImportSource @emotion/core */

import React from "react";
import styled from "@emotion/styled/macro";
import { DisableHsBox } from "./ui-common";

export const StyledMask = styled.div<{ active: boolean }>`
  background: black;
  opacity: ${(props) => (props.active ? 0.2 : 0)};
  pointer-events: ${(props) => (props.active ? "auto" : "none")};
  transition: opacity 0.2s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export default function Mask({ active = false, globalMask = false, ...attrs }) {
  return (
    <StyledMask active={active} {...attrs}>
      {globalMask && active && <DisableHsBox />}
    </StyledMask>
  );
}

/** @jsxImportSource @emotion/core */

import React from "react";
import css from "@emotion/css/macro";

import { useUiContext } from "./UiContext";
import { ViewportCanvas } from "./ui-common";
import ExternalWidget from "./ExternalWidget";

export default function VisualGrid() {
  const [uiContext] = useUiContext();
  if (!uiContext.gridActive) {
    return <></>;
  }
  return (
    <ExternalWidget>
      <ViewportCanvas
        css={css`
          pointer-events: none;
          z-index: 99999999;
        `}
      >
        <div
          className="row-fluid-wrapper dnd-section"
          style={{
            paddingTop: 0,
            marginTop: 0,
            paddingBottom: 0,
            marginBottom: 0,
            height: "100%",
          }}
        >
          <div className="row-fluid" style={{ height: "100%" }}>
            {Array(12)
              .fill(0)
              .map((i, index) => (
                <div
                  className="span1 dnd-column widget-span widget-type-cell"
                  key={index.toString()}
                  style={{ height: "100%", backgroundColor: "rgba(232, 83, 56, 0.1)" }}
                >
                  <div
                    style={{
                      height: "100%",
                      backgroundColor: "rgba(232, 83, 56, 0.1)",
                    }}
                  ></div>
                </div>
              ))}
          </div>
        </div>
      </ViewportCanvas>
    </ExternalWidget>
  );
}

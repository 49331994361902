/* eslint-disable no-restricted-globals */
/** @jsxImportSource @emotion/core */

import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";
import { css } from "@emotion/core";
import dayjs from "dayjs";
import { SavedPattern, useUiContext } from "../UiContext";
import Loading from "../Loading";
import { useAuthContext } from "../AuthContext";
import { useAppSettings } from "../AppSettings";
import { forceRefreshSectionData, insertPattern } from "../../api";
import { TextBox } from "../ui-common";

const InsertButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.2s opacity;
`;
const InsertButton = styled.button<{ color: "blue" | "purple" | "gray" }>`
  display: grid;
  align-items: center;
  justify-content: center;
  justify-items: center;
  grid-gap: 6px;
  grid-auto-flow: column;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  background: ${(props) => ({ blue: "#409cff", purple: "#8C4CF5", gray: "#888" }[props.color])};
  border-radius: 32px;
  padding: 6px 12px;
  margin-bottom: 6px;
  border: 0 none;
  cursor: pointer;
  color: white;
  appearance: none;
  box-sizing: border-box;
  min-width: 140px;
`;
const ToolTip = styled.div`
  position: absolute;
  right: 100%;
  margin-right: 25px;
  top: 50%;
  transform: translateY(-50%);
  background: black;
  width: 235px;
  padding: 20px;
  transition: 0.2s opacity;

  &::after {
    content: "";
    display: block;
    position: absolute;
    right: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-width: 12px 0 12px 12px;
    border-color: transparent transparent transparent #000000;
    border-style: solid;
  }
  h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }
  p {
    margin: 4px 0;
  }
  ul {
    margin: 4px 0;
  }
`;
const PatternItemBox = styled.div`
  border: 1px solid #adadad;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  margin-bottom: 20px;
  color: black;
  position: relative;
  transition: 0.2s background-color, 0.2s color;

  ${InsertButtonContainer}, ${ToolTip} {
    opacity: 0;
  }
  &:hover {
    background-color: black;
    color: white;

    ${InsertButtonContainer}, ${ToolTip} {
      opacity: 1;
    }
  }
`;
const Title = styled.p<{ editable: boolean }>`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: inherit;
  position: relative;

  ${(prop) =>
    prop.editable &&
    css`
      &::before {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        content: "Click here to edit/delete";
        opacity: 0;
      }
      ${PatternItemBox}:hover & {
        cursor: pointer;
        color: transparent;

        &::before {
          color: white;
          opacity: 1;
        }
      }
    `}
`;
const Image = styled.img`
  margin: 0;
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 5px;
  background-color: white;
`;
function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function PatternItem({ item, onInsert }: { item: SavedPattern; onInsert: (offset: number) => void }) {
  const appSettings = useAppSettings();
  const [uiContext, updateUi] = useUiContext();
  const editable = appSettings.portalId?.toString() === item.portal?.toString();
  const editPattern = useCallback(() => {
    if (!editable || !uiContext.sidebarActive) {
      return;
    }
    updateUi({
      sidebarActive: {
        mode: "save",
        existingPattern: item,
        areaId: uiContext.sidebarActive.areaId,
        sectionId: uiContext.sidebarActive.sectionId,
      },
    });
  }, [editable, item, uiContext.sidebarActive, updateUi]);
  const usedModules = (item.usedModules || [])
    .map((x) => {
      if (typeof x !== "string") {
        return null;
      }
      const m = /^@hubspot\/(.+)$/.exec(x);
      if (!m) {
        return x;
      }
      return toTitleCase(m[1].replace(/_/g, " ")) + " (default)";
    })
    .filter((x) => x);
  return (
    <PatternItemBox>
      {(item.image && <Image src={item.image} />) || (
        <div
          css={css`
            background: #ccc;
            padding-bottom: 75%;
            margin-bottom: 5px;
          `}
        ></div>
      )}
      <InsertButtonContainer>
        <InsertButton color="blue" onClick={() => onInsert(0)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" fill="none" viewBox="0 0 11 12">
            <path
              fill="currentColor"
              d="M.14 5.907l.446.469c.117.117.305.117.398 0l3.657-3.633v7.992a.27.27 0 00.28.282h.657c.14 0 .281-.117.281-.282V2.743l3.633 3.633c.094.117.281.117.399 0l.445-.469c.117-.093.117-.281 0-.398L5.437.61a.27.27 0 00-.398 0L.141 5.51c-.118.117-.118.305 0 .398z"
            ></path>
          </svg>
          Insert above
        </InsertButton>
        {item.source && (
          <InsertButton color="gray" onClick={() => window.open(item.source, "_blank")}>
            See Figma
          </InsertButton>
        )}
        <InsertButton color="purple" onClick={() => onInsert(1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" fill="none" viewBox="0 0 11 12">
            <path
              fill="currentColor"
              d="M10.336 5.65l-.445-.47c-.118-.116-.305-.116-.399 0L5.86 8.815V.798c0-.14-.14-.281-.28-.281h-.657a.285.285 0 00-.281.28v8.017L.984 5.18c-.093-.117-.28-.117-.398 0l-.445.468c-.118.094-.118.282 0 .399l4.898 4.898a.27.27 0 00.399 0l4.898-4.898c.117-.117.117-.305 0-.399z"
            ></path>
          </svg>
          Insert below
        </InsertButton>
      </InsertButtonContainer>
      <Title editable={editable} onClick={editPattern}>
        {item.name}
      </Title>
      <ToolTip>
        {item.portal && (
          <>
            <h5>Source portal</h5>
            <p>{item.portal}</p>
          </>
        )}
        {item.creator && (
          <>
            <h5>Creator</h5>
            <p>{item.creator}</p>
          </>
        )}
        {item.timestamp && (
          <>
            <h5>Created on</h5>
            <p>{dayjs(item.timestamp).format("ll")}</p>
          </>
        )}
        {item.themeName && (
          <>
            <h5>Theme</h5>
            <p>{item.themeName}</p>
          </>
        )}
        <h5>Modules</h5>
        <ul>
          {usedModules.map((x) => (
            <li key={x}>{x}</li>
          ))}
        </ul>
      </ToolTip>
    </PatternItemBox>
  );
}
const SearchInputContainer = styled.div`
  margin-bottom: 20px;

  div {
    position: relative;
  }
  svg {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }
  p {
    margin: 12px 0 0;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }
`;
function SearchBox({ text, onChange }: { text: string; onChange: (newText: string) => void }) {
  return (
    <SearchInputContainer>
      <div>
        <TextBox
          css={css`
            margin: 0;
          `}
          placeholder="Search pattern..."
          value={text}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        />
        {text ? (
          <svg
            onClick={() => onChange("")}
            css={css`
              cursor: pointer;
            `}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
          >
            <rect width="16" height="16" fill="#C4C4C4" rx="8"></rect>
            <path
              fill="#fff"
              d="M12 4.806L11.194 4 8 7.194 4.806 4 4 4.806 7.194 8 4 11.194l.806.806L8 8.806 11.194 12l.806-.806L8.806 8 12 4.806z"
            ></path>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path
              fill="#696969"
              fillRule="evenodd"
              d="M7.333 14a6.667 6.667 0 115.267-2.579l4.49 4.49-1.18 1.178-4.489-4.49A6.638 6.638 0 017.333 14zm5-6.667a5 5 0 11-10 0 5 5 0 0110 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        )}
      </div>
      {text && (
        <p>
          Results for <strong>“{text.trim()}”</strong>
        </p>
      )}
    </SearchInputContainer>
  );
}
export default function InsertPattern() {
  const authContext = useAuthContext();
  const appSettings = useAppSettings();
  const [uiContext] = useUiContext();
  const [state, setState] = useState("normal" as "normal" | "inserting" | "inserted" | "error");
  const [searchText, setSearchText] = useState("");
  const [insertedPattern, setInsertedPattern] = useState({} as SavedPattern);
  const sidebarActive = uiContext.sidebarActive;
  const doInsert = useCallback(
    (pattern: SavedPattern, offset: number) => {
      if (!authContext.authenticated || !sidebarActive || sidebarActive.mode !== "insert") {
        return;
      }
      setState("inserting");
      insertPattern({
        id: pattern.id,
        token: authContext.token,
        pageId: appSettings.pageId!,
        dndAreaId: sidebarActive.areaId,
        rowId: sidebarActive.sectionId + offset,
      })
        .catch((e) => {
          return e.json ? e.json().then(Promise.reject.bind(Promise)) : Promise.reject(e);
        })
        .catch((e) => {
          if (e.error !== "Page has no specified area ID") {
            return Promise.reject(e);
          }
          console.error(e);
          console.log("Trying to force refresh section data");
          return forceRefreshSectionData({
            token: authContext.token,
            pageId: appSettings.pageId!,
          }).then(() =>
            insertPattern({
              id: pattern.id,
              token: authContext.token,
              pageId: appSettings.pageId!,
              dndAreaId: sidebarActive.areaId,
              rowId: sidebarActive.sectionId + offset,
            })
          );
        })
        .then((data) => {
          setInsertedPattern(pattern);
          setState("inserted");
        })
        .catch((e) => {
          console.error(e);
          setState("error");
        });
    },
    [authContext, appSettings, sidebarActive]
  );
  if (!authContext.authenticated || !sidebarActive || sidebarActive.mode !== "insert") {
    return <></>;
  }
  if (state === "inserting") {
    return <Loading />;
  }
  if (state === "inserted") {
    return (
      <h4>
        "{insertedPattern.name}" has been inserted in this page. Please refresh your browser window! <br />
        BRiX 2 is still in beta, if you experience any issue on the inserted pattern, please ping us via{" "}
        <a href="mailto:support@inboundlabs.co">our email</a>, thank you!
      </h4>
    );
  }
  if (state === "error") {
    return (
      <h4>
        Failed to insert your pattern, if you just created the page, please try cloning any section on the page and try
        again.
      </h4>
    );
  }
  return (
    <>
      <SearchBox text={searchText} onChange={setSearchText} />
      {uiContext.savedPatterns
        .filter((x) => x.name?.toLowerCase()?.includes(searchText.toLowerCase().trim()))
        .map((x) => (
          <PatternItem item={x} key={x.id} onInsert={(offset) => doInsert(x, offset)} />
        ))}
    </>
  );
}

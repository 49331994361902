import React, { useCallback, useEffect, useState } from "react";
import {
  deletePattern,
  forceRefreshSectionData,
  getSavedPatterns,
  savePattern,
  setDirty,
  updatePattern,
} from "../../api";
import { useAppSettings } from "../AppSettings";
import { useAuthContext } from "../AuthContext";
import Loading from "../Loading";
import { TestOnly } from "../TestPortal";
import { GenericFormContainer, StyledButton, Textarea, TextBox } from "../ui-common";
import { useUiContext } from "../UiContext";

export default function SavePattern() {
  const authContext = useAuthContext();
  const appSettings = useAppSettings();
  const [uiContext, updateUi] = useUiContext();
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [source, setSource] = useState("");
  const [global, setGlobal] = useState(false);
  const [description, setDescription] = useState("");
  const [state, setState] = useState(
    "normal" as "normal" | "saving" | "saved" | "error" | "deleteConfirm" | "deleting" | "deleted" | "deleteError"
  );
  const sidebarActive = uiContext.sidebarActive;
  const existingPattern = (sidebarActive && sidebarActive.mode === "save" && sidebarActive.existingPattern) || null;
  useEffect(() => {
    if (existingPattern) {
      setName(existingPattern.name);
      setImage(existingPattern.image);
      setSource(existingPattern.source);
      setGlobal(existingPattern.global);
      setDescription(existingPattern.description);
    }
  }, [existingPattern]);
  const doSave = useCallback(() => {
    if (!authContext.authenticated || !sidebarActive || sidebarActive.mode !== "save") {
      return;
    }
    setState("saving");
    let promise: Promise<unknown>;
    if (existingPattern) {
      promise = updatePattern({
        name,
        description,
        image,
        source,
        global,
        token: authContext.token,
        patternId: existingPattern.id,
      });
    } else {
      promise = savePattern({
        name,
        description,
        image,
        source,
        global,
        token: authContext.token,

        pageId: appSettings.pageId!,
        dndAreaId: sidebarActive.areaId,
        rowId: sidebarActive.sectionId,
      })
        .catch((e) => {
          return e.json ? e.json().then(Promise.reject.bind(Promise)) : Promise.reject(e);
        })
        .catch((e) => {
          if (e.error !== "Page has no specified area ID") {
            return Promise.reject(e);
          }
          console.error(e);
          console.log("Trying to force refresh section data");
          return forceRefreshSectionData({
            token: authContext.token,
            pageId: appSettings.pageId!,
          }).then(() =>
            savePattern({
              name,
              description,
              image,
              source,
              global,
              token: authContext.token,
              pageId: appSettings.pageId!,
              dndAreaId: sidebarActive.areaId,
              rowId: sidebarActive.sectionId,
            })
          );
        });
    }
    promise
      .then(() => getSavedPatterns({ token: authContext.token, pageId: appSettings.pageId! }))
      .then((result) => {
        updateUi({ savedPatterns: result });
        setState("saved");
      })
      .catch((e) => {
        console.error(e);
        setDirty();
        setState("error");
      });
  }, [
    authContext,
    sidebarActive,
    name,
    description,
    image,
    source,
    global,
    appSettings.pageId,
    updateUi,
    existingPattern,
  ]);
  const doDelete = useCallback(() => {
    if (!authContext.authenticated || !sidebarActive || sidebarActive.mode !== "save" || !existingPattern) {
      return;
    }
    setState("deleting");
    deletePattern({
      token: authContext.token,
      patternId: existingPattern.id,
    })
      .then(() => {
        updateUi({ savedPatterns: uiContext.savedPatterns?.filter((x) => x.id !== existingPattern.id) });
        setState("deleted");
      })
      .catch((e) => {
        console.error(e);
        setState("deleteError");
      });
  }, [authContext, existingPattern, sidebarActive, uiContext.savedPatterns, updateUi]);
  if (!authContext.authenticated || !sidebarActive || sidebarActive.mode !== "save") {
    return <></>;
  }
  if (state === "saving" || state === "deleting") {
    return <Loading />;
  }
  if (state === "saved") {
    return <h4>Successfully saved your pattern!</h4>;
  }
  if (state === "deleted") {
    return <h4>Successfully deleted your pattern!</h4>;
  }
  if (state === "error") {
    return (
      <h4>
        Failed to save your pattern, if you just created the page, please try cloning any section on the page and try
        again.
      </h4>
    );
  }
  if (state === "deleteError") {
    return <h4>Failed to delete your pattern, please try again later.</h4>;
  }
  if (state === "deleteConfirm") {
    return (
      <>
        <h4>Are you sure you want to delete "{existingPattern?.name}"?</h4>
        <GenericFormContainer>
          <StyledButton cls="danger" onClick={doDelete}>
            Delete
          </StyledButton>
        </GenericFormContainer>
        {existingPattern && <StyledButton onClick={() => setState("normal")}>Cancel</StyledButton>}
      </>
    );
  }
  return (
    <>
      <TextBox
        placeholder="Name (optional)"
        value={name}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
      />
      <TextBox
        placeholder="Image URL (optional)"
        value={image}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setImage(e.target.value)}
      />
      <TextBox
        placeholder="Figma source URL (optional)"
        value={source}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSource(e.target.value)}
      />
      <Textarea
        placeholder="Description (optional)"
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => setDescription(e.target.value)}
      />
      <TestOnly>
        <GenericFormContainer>
          <label>
            <input
              type="checkbox"
              checked={global}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGlobal(e.target.checked)}
            />{" "}
            Share this pattern
          </label>
          {global && (
            <div>
              <label>
                <input type="radio" checked={true} /> Share publicly
              </label>
              <label>
                <input type="radio" checked={false} /> Share in this portal
              </label>
              <label>
                <input type="radio" checked={false} /> Share in my team
              </label>
            </div>
          )}
        </GenericFormContainer>
      </TestOnly>
      <GenericFormContainer>
        <StyledButton onClick={doSave}>Save</StyledButton>
      </GenericFormContainer>
      {existingPattern && (
        <StyledButton onClick={() => setState("deleteConfirm")} cls="danger">
          Delete
        </StyledButton>
      )}
    </>
  );
}

/** @jsxImportSource @emotion/core */

import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";

import SideBar from "./sidebar/SideBar";
import { UiContextProvider, useUiContext } from "./UiContext";
import { AuthContextProvider, useAuthContext } from "./AuthContext";
import { ViewportCanvas } from "./ui-common";
import VisualGrid from "./VisualGrid";
import SectionController from "./SectionController";
import Mask from "./Mask";
import SignInPopup from "./SignInPopup";
import SavePattern from "./sidebar/SavePattern";
import { forceSave, getSavedPatterns } from "../api";
import InsertPattern from "./sidebar/InsertPattern";
import Loading from "./Loading";
import WelcomePopup from "./WelcomePopup";
import PortalSignUpPopup from "./PortalSignUpPopup";
import { useAppSettings } from "./AppSettings";
import { isTestPortal } from "./TestPortal";

const StyledAppRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 2147483599;
  color: black;

  font-family: "Avenir Next W02", sans-serif;
  font-size: 14px;
  line-height: 150%;
`;

function AppRoot() {
  const [active] = useState(true);
  const [uiContext, updateUi] = useUiContext();
  const appSettings = useAppSettings();
  const authContext = useAuthContext();
  useEffect(() => {
    document.body.classList.toggle("brix-activated", active);
    return () => {
      document.body.classList.remove("brix-activated");
    };
  }, [active]);
  useEffect(() => {
    if (uiContext.savedPatterns.length) {
      return;
    }
    if (!authContext.authenticated) {
      return;
    }
    getSavedPatterns({ token: authContext.token, pageId: appSettings.pageId || "" }).then((data) => {
      if (uiContext.savedPatterns.length) {
        return;
      }
      let showPortalSignUpForm = false;
      const portalSignUpFinishedKey = `brixPortalSignUpFinished${appSettings.portalId}`;
      if (!localStorage[portalSignUpFinishedKey]) {
        /*
        if (data.length) {
          localStorage.setItem(portalSignUpFinishedKey, "1");
        } else {
          showPortalSignUpForm = true;
        }*/
        showPortalSignUpForm = true;
      }
      updateUi({
        savedPatterns: data,
        ...(showPortalSignUpForm
          ? {
              activePopup: "portalSignUp",
            }
          : {}),
      });
    });
  }, [authContext, uiContext.savedPatterns.length, updateUi, appSettings]);
  useEffect(() => {
    if (
      isTestPortal(appSettings.portalId!) &&
      !localStorage.brixGetStartedShown &&
      !(window as any).__brixDisableWelcomePopup &&
      !uiContext.activePopup &&
      !authContext.authenticated &&
      document.querySelector(".dnd-section")
    ) {
      updateUi({ activePopup: "welcome" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (sessionStorage["brixPendingForceSave"]) {
    forceSave();
    return (
      <StyledAppRoot>
        <ViewportCanvas>
          <Mask globalMask={true} active={true} />
          <Loading center={true} />
        </ViewportCanvas>
      </StyledAppRoot>
    );
  }
  return (
    <StyledAppRoot>
      {active ? <SectionController /> : undefined}
      <ViewportCanvas>
        <Mask
          active={!!uiContext.sidebarActive || !!uiContext.activePopup}
          globalMask={true}
          onClick={() => updateUi({ sidebarActive: false, activePopup: "" })}
        />
        <SideBar>
          {!!uiContext.sidebarActive && uiContext.sidebarActive.mode === "save" && <SavePattern />}
          {!!uiContext.sidebarActive && uiContext.sidebarActive.mode === "insert" && <InsertPattern />}
        </SideBar>
        {uiContext.activePopup === "signIn" && <SignInPopup />}
        {uiContext.activePopup === "welcome" && <WelcomePopup />}
        {uiContext.activePopup === "portalSignUp" && <PortalSignUpPopup />}
      </ViewportCanvas>
      <VisualGrid />
    </StyledAppRoot>
  );
}
function App() {
  return (
    <div className="App brix-shadow-app-root">
      <UiContextProvider>
        <AuthContextProvider>
          <AppRoot />
        </AuthContextProvider>
      </UiContextProvider>
    </div>
  );
}

export default App;
